footer{
	width: 100%;
	position: relative;
	padding: @margin 0;
	float: left;
	.paper;
	padding: @margin * 2;


	.footer_logo{
		// width: 105px;
		// height: 105px;

		// position: relative;
		// float: left;
		// margin-right: @margin;
		width: 100%;

		background-image: url(../img/BMK_XMAS19_WEBSITE-Logo_EXP500.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@media @mobile-max{
			background-size: 50% auto;
		}

		&:before{
			content: '';
			display: block;
			padding-bottom: 60%;

			@media @mobile-min{
				padding-bottom: 100%;
			}
		}
	}

	.footer_nav{
		a{
			display: block;
			font-size: 23px;
			line-height: 30px;
			color: @color-brand-contrast;
			font-family: @headline;
			border: 0;
			
			@media @mobile-min{
				line-height: 33px;
			}
		}
	}
	.footer_imprint{
		color: @color-brand-contrast;
		font-size: 15px;
		// font-size: 27px;
		// line-height: 33px;

		p span{
			border-bottom: 1px solid;
		}
	}
	.footer_social{
		color: @color-brand-contrast;
		text-align: center;
		font-size: 15px;
		// height: 100%;

		a{
			width: 30px;
			background-color: @color-brand-contrast;
			margin: 0 @margin @margin 0;
			padding: @margin / 2;

			&:before{
				color: @color-brand;
			}
		}


		p{
			// margin-top: 81px;
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		}

	}
}