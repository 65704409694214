header{
	width: 100%;
	position: fixed;
	top: 0;
	padding: @margin 0 0;
	background-color: white;
	z-index: 5;

	height: @height_header;
	background-color: @color-brand-contrast;

	transition: all .5s ease;
	transform: translateY(0);

	// box-shadow: 0px 5px 10px 0px #444;

	@media @mobile-max{
		padding: 0;
		height: 65px;
	}

	& + *{
		margin-top: @height_header;

		@media @mobile-max{
			margin-top: 65px;
		}

		.page_index &{
			margin-top: 0;
		}
	}

	.page_index &{
		transform: translateY(-@height_header - 40);
		// transition: transform .2s ease;
	}

	.page_index.show_header &{
		transform: translateY(0);
	}

	.headerOpen &{
		@media @mobile-max{
			// height: 100vh;
			height: ~'calc(100vh - @{margin} )';
		}
	}

	.showing_vendor{
		transform: translateY(0) !important;
	}

	// &:after{
	// 	content: '';
	// 	position: absolute;
	// 	bottom: -40px;
	// 	left: 0;
	// 	height: 40px;

	// 	border-style: solid;
	// 	border-width: 40px 100vw 0 0;
	// 	border-color: white transparent transparent transparent;

	// 	@media @mobile-max{
	// 		bottom: -20px;
	// 		height: 20px;
	// 		border-width: 20px 100vw 0 0;
	// 	}
	// }

	.header_interior{
		// padding-left: 125px;
		position: relative;
		// height: auto;
		height: 100%;

		.headerOpen &{
			// height: ~'calc(100vh - @{margin} )';
		}

		@media @mobile-max{
			padding-left: @margin;
			padding-right: @margin;
		}

		.header_logo{
			width: 105px;
			height: 105px;

			position: absolute;
			top: 0;
			left: 0;
			margin-right: @margin;
			z-index: 2;

			background-image: url(../img/logo_waisenhausplatz-02.png);
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
			border: 0;

			.headerOpen &{
				display: none;
			}

			@media @mobile-max{
				left: @margin;
				top: 3px;

				width: 75px;
				height: 75px;
			}
		}
		.header_hamburger{
			position: absolute;
			right: @margin;
			top: 8px;
			height: 50px;
			width: 50px;
			line-height: 50px;
			text-align: center;
			font-size: 26px;
			color: white;
			z-index: 5;

			.headerOpen &{
				color: @color-brand-contrast;
			}

			@media @mobile-min{
				display: none;
			}
		}
		.header_nav{

			overflow: hidden;

			@media @mobile-max{
				position: absolute;
				top: 0;
				left: 0;
				height: 100vh;
				width: 100vw;
				background-color: white;
				transform: translateY(-100%);
				padding-top: 100px;
				text-align: center;
				transition: all .5s ease;

				.headerOpen &{
					transform: translateY(0);
				}
		
				// }
			}

			a{
				position: relative;
				float: left;

				padding: 35px 27px 0;
				line-height: 40px;
				color: black;
				font-family: axehandelregular;
				text-transform: uppercase;
				border: 0;
				text-rendering: geometricPrecision;
				font-size: 30px;					
				width: 100%;
				transform: translateZ(0);
				color: @color-brand;

				@media @mobile-min{
					width: auto;
					font-size: 20px;					
					padding: 0 10px 0;
				}

				@media @tablet-min{
					font-size: 25px;
					padding: 0 20px 0;
				}


				@media @desktop-min{
					font-size: 28px;
					padding: 0 27px 0;
				}

				&.current{

					@media @mobile-min{

						color: white;

						&:before{
							content: '';
						}
					}
					// border-bottom: 1px solid @color-brand;

					&:before{
						position: absolute;
						height: 1px;
						bottom: 1px;
						left: 0;
						background-color: @color-brand;
						width: 100%;

						@media @mobile-min{   width: ~'calc(100% - 20px)'; left: 10px; } 
						@media @tablet-min{   width: ~'calc(100% - 40px)'; left: 20px; } 
						@media @desktop-min{  width: ~'calc(100% - 54px)'; left: 27px; } 
					}
				}

				&:before{

				}
			}
		}
		.header_social{
			// position: relative;
			// float: right;

			position: absolute;
			right: 0;
			top: 0;


			@media @mobile-max{
				// display: none;
				// position: fixed;
				top: auto;
				bottom: @margin;
				left: @margin;
				right: @margin;
				display: none;

				.headerOpen &{
					display: block;
				}
		
				// }
			}

			a{
				width: 30px;
				line-height: 20px;
				margin: 45px @margin @margin / 2 0;
				text-align: center;
				font-size: 18px;
				position: relative;
				border: 0;

				&:before{
					position: relative;
					color: white;
					z-index: 2;
				}

				&:after{
					content: '';
					background-color: black;
					position: absolute;
					top: 50%;
					left: 50%;
					height: 30px;
					width: 30px;
					margin: -15px 0 0 -15px;
					z-index: 1;
					
				}
			}		
		}

	}
}