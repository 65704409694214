.grid, .vendor_grid{

	@media @mobile-min{
		padding-top: 35px;
	}

	.vendor_grid_filter{
		padding: 0 @margin;
		margin: @margin * 2 auto @margin * 5;

		@media @mobile-max{
			margin: @margin auto @margin * 2;
		}

		.vendor_grid_filter_option{
			padding: 0 @margin / 2;
			text-align: center;
			cursor: pointer;
			margin-bottom: @margin;

			@media @mobile-min{
				margin-bottom: 0;
				// flex: 1;
			}

			&:last-of-type{
				margin-right: 0;
			}

			&.selected span{
				background-color: @color-brand !important;
				color: black !important;
			}

			span{
				padding: @margin 0;
				// padding:
				display: block;
				font-family: axehandelregular;
				text-transform: uppercase;
				background-color: black;
				color: white;
				line-height: 25px;
				font-size: 30px;

				@media @mobile-min{
					line-height: 50px;
					font-size: 55px;
				}

				&:hover, &:active{
					background-color: white;
					color: black;
				}
			}
		}
	}

	.vendor_grid_interior{

		margin-bottom: @margin * 6;

		.tile{
			display: none;
			position: relative;
			cursor: pointer;
			text-decoration: none;
			border-bottom: 0;
			padding-left: @margin / 2;
			padding-right: @margin / 2;
			padding-bottom: @margin / 2;

			&.aktiv{
				display: block;
			}

			@media @mobile-min{
				padding-bottom: @margin;
			}

			&:before{
				content: '';
				display: block;
				padding-bottom: 100%;
			}

			&:hover, &:focus{
				.tile_interior .tile_text{
					display: table;
					background-color: rgba(38, 22, 96, .7);

					.tile_text_interior{
						color: @color-brand !important;
					}
				}
			}

			.tile_interior{
				position: absolute;
				top: 0;
				// top: @margin;
				// left: 0;
				left: @margin / 2;
				// height: ~'calc(100% - (@{margin} * 2))';
				// width: ~'calc(100% - (@{margin} * 2))';
				right: @margin / 2;
				// bottom: @margin;
				bottom: @margin / 2;
				// width: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;

				text-align: center;

				@media @mobile-min{
					bottom: @margin;
				}

				.tile_text{
					height: 100%;
					width: 100%;
					position: relative;
					display: table;
					background-color: rgba(27, 76, 55, .7);
					display: none;

					@media @mobile-max{
						display: table;
						background-color: transparent;
						// color: white;
					}

					.tile_text_interior{
						display: table-cell;
						vertical-align: middle;

						color: @color-brand;
						font-family: axehandelregular;
						font-size: 40px;
						line-height: 50px;

						@media @mobile-max{
							color: white;
						}

						// .tile_text_title:after{
						// 	content: '---';
						// 	display: block;
						// 	position: relative;
						// 	// bottom: 0;
						// }

						p:last-of-type{
							span:after{
								margin: 0 0 0 6px;
								position: relative;
								top: -3px;
								content: '|';
							}

							span:last-of-type:after{
								content: '';
							}
						}
					}
				}
			}
		}

	}
}









