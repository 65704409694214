.page_index{
}

.index_hero{
	overflow: hidden;
	height: 100vh;
	cursor: pointer;

	// @media (min-aspect-ratio: 1/3) {
	// 	height: 80vh !important;
	// }

	@media @mobile-min {
		// height: 65vh;
		height: 70vh;
	}

	// @media screen and (max-height: 799px) and (min-width: 767px) {
	// @media screen and (min-height: 600px) and (max-height: 799px) and (max-width: 767px) {
	// 	height: 47vh !important;
	// }

	@media screen and (min-height: 800px) and (max-width: 400px) {
		height: 80vh !important;
	}

	@media screen and (min-height: 920px) and (min-width: 767px) and (max-width: @screen-sm-max) {
	// @media screen and (min-height: 920px) and (min-width: 767px) and @tablet-max{
		height: 55vh !important;
	}

	@media @tablet-min {
		height: 85vh;
	}

	@media @desktop-min {
		height: 100vh;
	}

	.summer & {
		height: 100vh !important;
		cursor: default;
	}

  .index_hero_background{
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
		height: 100%;
		.paper;  	
  }

	.index_hero_illustration{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		// left: 0;
		// left: 5vw;
		// right: 5vw;
		// top: 5vh;
		// bottom: 5vh;
		// bottom: 5vh;
		// top: 10vh;
		// height: 100vh;

		// background-image: url(../img/homepage_illustration_mobile_01.png);
		// background-image: url(../img/BWMW2018_HEADER-MOBILE_V04.png);
		// background-image: url(../img/BMK_XMAS20_WEBSITE-MOBILE_RZ-EXP.png);
		background-image: url(../img/BMK_XMAS21_WEBSITE-MOBILE_RZ-EXP.png);

		// background-size: contain;
		// background-position: top center;

		// background-size: 1450px 1450px;

		// background-size: auto 135vh;
		// background-size: 205vw auto;
		// background-size: auto 100vh;
		// background-size: 90vw auto;
		background-size: auto 100%;

		// background-position: 50% 50%;
		background-position: top center;

		// background-position: 50%;
		// background-size: 90vw auto;
		background-repeat: no-repeat;

		@media @mobile-min {
			// background-position: center center;
			// background-image: url(../img/homepage_illustration_10.png);
			// background-image: url(../img/BWMW2018_HEADER-DESKTOP_V01.png);
			// background-image: url(../img/BMK_XMAS20_WEBSITE-DESKTOP_RZ-EXP.png);
			background-image: url(../img/BMK_XMAS21_WEBSITE-DESKTOP_RZ-EXP.png);
			// background-size: 120vw auto;
			// background-position: 50% 52.5%;
			background-size: 100% auto;
			// background-position: center center;
			background-position: center center;
			// background-position: 50% 5vh;
			// background-position: 50% 5vh;
			// background-size: auto 90vh;
			// left: -58vw;
			// right: -14vw;
		}		

		@media @desktop-min {
			// background-position: 50% 50%;
		}

		@media @huge-min{
			// background-size: cover;
		}
	}

	.index_hero_arrow{
		position: absolute;
		bottom: 0;
		left: 50%;
		height: 50px;
		width: 50px;
		margin-left: -25px;
		display: none;
		// background-color: green;
		
		@media @mobile-min{
			// display: block;
			bottom: 10px;
		}

		&:before, &:after{
			content: '';
			height: 4px;
			width: 20px;
			background-color: white;
			position: absolute;
			left: 50%;
			top: 50%;
			@media @mobile-min{
				width: 40px;
			}
			// top: -50px;
		}

		&:before{
			margin: -2px 0 0 -16px;
			transform: rotate(45deg);

			@media @mobile-min{
				margin: -2px 0 0 -33px;
			}
		}
		&:after{
			margin: -2px 0 0 -4px;
			transform: rotate(-45deg);

			@media @mobile-min{
				margin: -2px 0 0 -6px;
			}
		}
	}

	// .index_hero_logo{
	// 	// margin-top: @margin * 4;
	// 	// background-image: url(../img/logo_waisenhausplatz-01.png);
	// 	// background-size: cover;
	// 	// background-position: top center;
	// 	// background-repeat: no-repeat;

	// 	position: absolute;
	// 	top: 50vh;
	// 	left: 50vw;
	// 	width: 100%;
	// 	height: 100%;
	// 	max-width: 346px;
	// 	max-height: 346px;
	// 	transform: translate(-50%, -50%);

	// 	border-radius: 50%;
	// 	z-index: 1;

	// 	@media @mobile-max{
	// 		max-width: 250px;
	// 		max-height: 250px;
	// 	}

	// 	&:before{
	// 		content: '';

	// 		background-image: url(../img/paper_1080.jpg);
	// 		background-size: auto 100vh;
	// 		background-position: center;
	// 		background-repeat: no-repeat;
	// 		// background-attachment: fixed;

	// 		position: absolute;
	// 		top: 0;
	// 		left: 0;
	// 		height: 100%;
	// 		width: 100%;
	// 		border-radius: 50%;
	// 		z-index: 1sa;
	// 	}

	// 	&:after{
	// 		content: '';
	// 		position: absolute;
	// 		// top: 5px;
	// 		// left: 5px;
	// 		// height: ~'calc(100% - 10px)';
	// 		// width: ~'calc(100% - 10px)';
	// 		top: 0;
	// 		left: 0;
	// 		height: 100%;
	// 		width: 100%;
	// 		z-index: 2;

	// 		background-image: url(../img/logo_waisenhausplatz-02.png);
	// 		background-size: 100%;
	// 		background-position: top center;
	// 		background-repeat: no-repeat;
	// 	}
	// }



	// .index_hero_teaser{
	// 	margin-top: ~'calc(100vh - 50px)';
	// 	width: 100%;
	// 	position: relative;
	// 	float: left;
	// 	padding: 0 @margin;
	// 	// margin-bottom: 150px;
	// 	// margin-bottom: 30vh;



	// 	&:before, &:after{
	// 		content: '';
	// 		height: 4px;
	// 		width: 40px;
	// 		background-color: white;
	// 		position: absolute;
	// 		left: 50%;
	// 		top: -50px;
	// 	}

	// 	&:before{
	// 		margin: -2px 0 0 -35px;
	// 		transform: rotate(45deg);
	// 	}
	// 	&:after{
	// 		margin: -2px 0 0 -9px;
	// 		transform: rotate(-45deg);
	// 	}

	// 	.index_hero_teaser_interior{
	// 		// height: 100%;
	// 		width: 100%;
	// 		max-width: 800px;
	// 		margin: 0 auto;
	// 		position: relative;
	// 		overflow: hidden;

	// 		background-image: url(../img/hero_card_bg_01.jpg);
	// 		background-size: cover;
	// 		background-position: center;
	// 		background-repeat: no-repeat;

	// 		box-shadow: 0px 0px 50px 4px #444;

	// 		padding: @margin * 4 @margin; 

	// 		@media @mobile-min{
	// 			padding: @margin * 8 @margin * 3; 
	// 		}

	// 		&:before, &:after{
	// 			content: '';
	// 			position: absolute;
	// 			display: block;
	// 			background-color: black;
	// 			height: 3px;
	// 			left: @margin;
	// 			right: @margin;

	// 			@media @mobile-min{
	// 				left: @margin * 3;
	// 				right: @margin * 3;
	// 			}
	// 		}

	// 		&:before{
	// 			top: @margin * 2;

	// 			@media @mobile-min{
	// 				top: @margin * 4;
	// 			}
	// 		}

	// 		&:after{
	// 			bottom: @margin * 2;

	// 			@media @mobile-min{
	// 				bottom: @margin * 4;
	// 			}
	// 		}


	// 		.index_hero_text{
	// 			position: relative;
	// 			width: 100%;

	// 			@media @mobile-min{
	// 				float: left;
	// 				width: 60%;
	// 				padding-right: @margin;
	// 			}


	// 			h1{
	// 				font-family: @headline;
	// 				text-rendering: geometricPrecision;
	// 				font-size: 44px;
	// 				color: black;
	// 				transform: translateZ(0);
	// 				margin-top: 0;

	// 				@media @mobile-max{
	// 					font-size: 30px;
	// 				}
	// 			}
	// 			p{
	// 				color: black;

	// 			}
	// 		}

	// 		.index_hero_text_logo{
	// 			width: 100%;
	// 			position: relative;
	// 			max-width: 200px;
	// 			margin: @margin * 2 auto @margin;
	// 			display: block;

	// 			@media @mobile-min{
	// 				float: left;
	// 				max-width: inherit;
	// 				width: 30%;
	// 				margin: 0 0 0 5%;

	// 			}
	// 			// position: absolute;
	// 			// right: 0;
	// 			// top: 0;
	// 			// bottom: 0;
	// 			// left: 60%;

	// 			// background-image: url(../img/IWG_465.png);
	// 			// background-size: 65% auto;
	// 			// background-position: center;
	// 			// background-repeat: no-repeat;


	// 			@media @mobile-max{
	// 				// display: none;
	// 				// left: 0;
	// 				// top: 60%;
	// 				// bottom: @margin;
	// 				// background-size: auto 90%;
	// 			}
	// 			@media @tiny-max{

	// 			}
	// 		}
	// 	}

	// }
}






