.vendor_information{
	// padding: 0;

	.vendor_hero{
		width: 100%;
		height: 70vh;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat; 
		filter: sepia(100%);
		display: table;
		position: relative;

		.vendor_hero_background{			
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;

			filter: sepia(100%) brightness(65%);

			background-size: cover;
			background-position: center;
			background-repeat: no-repeat; 
		}

		.vendor_hero_back{
			position: absolute;
			// background-color: blue;
			top: @margin;
			left: @margin;
			height: 50px;
			width: 50px;
			cursor: pointer;
			z-index: 2;

			&:before,
			&:after{
				content: '';
				top: 50%;
				left: 50%;
				height: 4px;
				width: 40px;
				margin: -2px 0 0 -20px;
				position: absolute;
				background-color: white;
			}

			&:before{ transform: rotate(-45deg); }
			&:after{  transform: rotate(45deg); }
		}

		.vendor_hero_availability{
			position: relative;
			z-index: 1;
			display: table-cell;
			vertical-align: middle;

			.vendor_hero_availability_interior{
				text-align: center;

				span{
					color: white;
					font-family: axehandelregular;
					// font-size: 277px;
					// line-height: 277px;


					

					font-size: 60px;
					line-height: 60px;

					@media @mobile-min{
						// font-size: 70px;
						// line-height: 70px;
						font-size: 150px;
						line-height: 150px;			
					}

					@media @tablet-min{
						font-size: 200px;
						line-height: 200px;
					}

					&:after{
						content: '|';
						margin: 0 @margin;
						position: relative;
						top: -20px;

						@media @mobile-min{
							top: -5px;
						}
					}

					&:last-of-type:after{
						content: '';
					}
				}

			}
		}
	}
	.vendor_title{
		.paper;
		padding: @margin * 2;

		h1{
			margin: 0;
			font-family: axehandelregular;
			color: @color-brand-contrast;
			font-size: 40px;
			text-transform: uppercase;

			@media @mobile-min{
				// font-size: 70px;
				// line-height: 70px;
				font-size: 70px;
			}

			@media @tablet-min{
				font-size: 103px;
			}
		}
	}

	.vendor_text{
		padding: @margin * 2;

		.vendor_text_output{
			h1, h2, h3, h4, h5, h6{
				font-size: 45px;
				color: @color-brand-contrast;
				font-family: axehandelregular;
			}

			p{
				// font-size: 27px;
				// line-height: 33px;
			}
		}

		// .vendor_logo

		.vendor_info{

			.vendor_logo{
				position: relative;
				float: left;
				width: 100%;
				height: 200px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;

				// padding-bottom: @margin;
				// border-bottom: 4px solid black;
				margin-bottom: @margin * 4;

				&:before{
					content: '';
					height: 12px;
					width: 100%;
					bottom: -@margin * 2;
					left: 0;
					position: absolute;
					background-color: @color-brand;
				}



			}
			.vendor_contact{
				position: relative;
				float: left;
				width: 100%;
				// font-size: 27px;
				// line-height: 33px;
				margin-bottom: @margin * 4;

				&:before{
					content: '';
					height: 12px;
					width: 100%;
					bottom: -@margin * 2;
					left: 0;
					position: absolute;
					background-color: @color-brand;
				}
			}
			.vendor_market_hours{
				margin-bottom: @margin * 2;

				h3{
					color: @color-brand;
					font-family: axehandelregular;
					text-transform: uppercase;
					font-size: 25px;

					@media @mobile-min{
						font-size: 30px;
					}

					@media @tablet-min{
						font-size: 45px;
					}

				}

				p{

				}
				span{

				}
			}
			.vendor_IWG_logo, .vendor_portrait{
				width: 100%;
				// background-color: @color-brand;
				// background-image: url(../img/IWG_465.png);
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				position: relative;
				float: left;


				&:before{
					content: '';
					display: block;
					padding-bottom: 110%;
				}

			}
		}
	}
	.vendor_quote{
		background-color: black;
		color: @color-brand;
		padding: @margin * 4 0;

		blockquote{
			border: 0;
			padding: @margin;
			margin: 0;
			text-align: center;

			p{
				font-size: 100px;
				line-height: 54px;
				font-family: axehandelregular;
				text-transform: uppercase;

				i, em{
					display: block;
					font-size: 36px;
					line-height: 54px;
				}
			}
		}

	}


}

.vendor_footer{
	height: 20vh;
	.paper;

	text-align: center;
	cursor: pointer;

	h2{
		line-height: 20vh;
		color: @color-brand-contrast;
		margin: 0;
	}

	
}



















