.hero{
	width: 100%;
	height: 70vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat; 
	filter: sepia(100%);
	display: table;
	// margin-bottom: @margin * 2;
	
	.vendor_hero_availability{
		display: table-cell;
		vertical-align: middle;

		.vendor_hero_availability_interior{
			text-align: center;

			span{
				color: white;
				font-family: @headline;
				font-size: 277px;
				line-height: 277px;

				&:after{
					content: '|';
				}

				&:last-of-type:after{
					content: '';
				}
			}

		}
	}
}