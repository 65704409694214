// @font-face {
//     font-family: 'NAME_OF_YOUR_WEBFONT';
//     src: url('PATH/TO/YOUR_WEBFONT_HERE.eot');
//     src: url('PATH/TO/YOUR_WEBFONT_HERE.eot?#iefix') format('embedded-opentype'),
//          url('PATH/TO/YOUR_WEBFONT_HERE.woff') format('woff'),
//          url('PATH/TO/YOUR_WEBFONT_HERE.ttf') format('truetype'),
//          url('PATH/TO/YOUR_WEBFONT_HERE.svg#NAME_OF_YOUR_WEBFONT') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

@font-face {
    font-family: 'iron__brine';
    src: url('../fonts/iron__brine-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'highvoltage_rough';
    src: url('../fonts/highvoltage_rough-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'axehandelregular';
    src: url('../fonts/axehandel-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}