.page_index{
	.index_hero{

	}

	.index_intro{
		padding-top: 0px;

		@media @mobile-min{
			padding-top: 40px;
		}
	}

	.content > h1{
		text-align: center;
		width: 100%;
		display: block;
		font-family: @headline;
		color: @color-brand-contrast;
		border: 0;
		padding: @margin 0;
		margin: @margin 0;
		font-size: 30px;
		line-height: 30px;

		@media @mobile-min{
			font-size: 70px;
			line-height: 70px;
			padding: @margin 0 @margin * 2;
			margin: @margin 0 @margin * 2;
		}

		@media @tablet-min{
			font-size: 100px;
			line-height: 100px;			
		}
	}

	#haendler{
		float: none;
	}

	#haendler, #weihnachtsmarkt{

	}

	#haendler, #weihnachtsmarkt, #stellen, #kontakt{
		// padding-top: @height_header;
		display: block;
		border: 0;
	}

	#stellen, #kontakt{
		// padding: @margin * 2 0 @margin * 4;
	}

}

.index_second {
	overflow: hidden;
	
	&:before{
		// padding-bottom: 55%;
		content: '';
		padding-bottom: 112%;
		display: block;

		@media @mobile-min {
			padding-bottom: 50%;
		}

		// @media @mobile-min {
		// 	padding-bottom: 36%;
		// }

		// @media @tablet-min {
		// 	padding-bottom: 36%;
		// }
		
		// @media @desktop-min {
		// 	padding-bottom: 36%;
		// 	// padding-bottom: 40%;
		// }
	}

	.second_graphic{
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
		height: 100%;
		.paper;  
	}

	.second_graphic_interior{
		position: absolute;
		left: 0;
		top: 0;		
  	width: 100%;
		height: 100%;
		display: block;
		outline: 0;
		border: 0;

		// right: 0; 
		// bottom: 0;

		// background-image: url(../img/BWMW2018_CANDLE_V01.svg);
		background-image: url(../img/BMK_XMAS19_WEBSITE-MOBILE_KERZENZIEHEN-DEF.png);
		// background-size: auto 110%;
		background-size: auto 90%;
		background-position: center;
		background-repeat: no-repeat;

		@media @mobile-min {
			// background-size: 110% auto;
			background-image: url(../img/BMK_XMAS19_WEBSITE-DESKTOP_KERZENZIEHEN_DEF.png);
			// background-size: 110% auto;
		}

		// svg{
		// 	position: absolute;
		// 	left: 50%;
		// 	transform: translateX(-50%);
		// 	top: -10%;
		// 	// width: 100%;
		// 	width: auto;
		// 	height: 120%;

		// 	&:before{
		// 		content: '';
		// 		position: relative;
		// 		padding-bottom: 52%;
		// 	}

		// 	a{
		// 		cursor: pointer;

		// 		&:hover path, &:focus path{
		// 			fill: white !important;
		// 			stroke: white !important;
		// 		}
		// 	}
		// }
	}
}

.second_graphic{
	// padding: 40px;
	// .paper;

 //  @media screen and (max-width: 1920px){
	//   // background-position: center;
 //  	// background-size: 1920px auto;
 //  }


	// .second_graphic_interior{
	// 	position: absolute;
	// 	left: 0;
	// 	right: 0;
	// 	top: 0;
	// 	bottom: 0;


	// 	// width: 100%;
	// 	// max-width: 1024px;
	// 	// margin: 0 auto;
	// 	// position: relative;
	// 	// padding: 20px;
	// 	// background-image: url(../img/homepage_illustration_second_01.png);
	// 	background-image: url(../img/BWMW2018_CANDLE_V01.png);
	// 	// background-size: contain;
	// 	background-size: auto 120%;
	// 	background-position: center;
	// 	background-repeat: no-repeat;

	// 	svg{
	// 		position: absolute;
	// 		left: 0;
	// 		top: 0;
	// 		width: 100%;
	// 		height: 100%;

	// 		a{
	// 			cursor: pointer;

	// 			&:hover path, &:focus path{
	// 				fill: white !important;
	// 			}
	// 		}
	// 	}


	// }
}

.second_text{
	background-color: @color-brand-contrast;
	padding: 40px;
	text-align: center;
	// color: white;

	h2{
		color: @color-brand;
		font-family: highvoltage_rough;
		font-size: 30px;
		margin-bottom: 50px;

		@media @mobile-min{
			font-size: 70px;
		}

		span{
			color: white;
			display: block;

			i{
				font-style: normal;
				font-family: axehandelregular;
				font-size: 39px;
				margin: 0 4px;
				
				@media @mobile-min{
					font-size: 86px;
				}
			}

			a {
				font-size: 80%;
				display: inline-block;
				// line-height: 40px;
			}
		}
	}

}


