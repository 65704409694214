.market_extra_market_divider{
	display: table;
	position: relative;
	width: 100%;
	cursor: pointer;
	background-color: black;

	height: 30vh;

	@media @mobile-min{
		height: 50vh;
	}

	// &:before, &:after{
	// 	content: '';
	// 	height: 4px;
	// 	width: 40px;
	// 	background-color: @color-brand;
	// 	position: absolute;
	// 	left: 50%;
	// 	bottom: 50px;
	// }

	// &:before{
	// 	margin: -2px 0 0 -13px;
	// 	transform: rotate(45deg);
	// }
	// &:after{
	// 	margin: -2px 0 0 13px;
	// 	transform: rotate(-45deg);
	// }

	.market_extra_market_divider_interior{
		display: table-cell;
		vertical-align: middle;
		text-align: center;

		h1{
			color: @color-brand;
			font-family: @headline;

			font-size: 60px;
			line-height: 60px;

			@media @mobile-min{
				font-size: 70px;
				line-height: 70px;
			}

			@media @tablet-min{
				font-size: 150px;
				line-height: 150px;			
			}
		}
	}
}

.market_section{


	// background-color: blue;

	// &.closed{

	// }

	&:nth-of-type(11){


		margin-bottom: @margin * 4;

		.market_title_interior:after{
			background: none !important;
		}
		// background-color: green;
	}

	&.market_haupt{
		// padding-top: 100px;
		// margin-bottom: -100px + @margin;
		margin-bottom: @margin;
	}
	&.market_extra{
		
	}

	&.closed.market_haupt{
		height: 70vh;
		overflow: hidden;
	}
	&.closed.market_extra{
		height: 100px;
		overflow: hidden;
	}

	.market_hero{

	}


	.market_title{
		display: table;
		position: relative;
		height: 100%;
		width: 100%;
		cursor: pointer;

		.market_haupt&{

		}

		.market_extra&{
			height: 100px;
		}

		.closed.market_extra&{
			// display: none;
		}

		

		

		.market_title_interior{
			display: table-cell;
			vertical-align: middle;



			.market_haupt&{

			}
			.market_extra&{
				padding-left: @margin;
				padding-right: @margin;
				// padding-right: 70px;

				&:after{
					content: '';
					position: absolute;
					// bottom: -@margin * 2;
					bottom: 0;
					height: 15px;
					left: 0;
					right: 0;
					background: linear-gradient(to bottom, rgba(220,220,220,1) 0%,rgba(220,220,220,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					display: none;

					.closed&{
						display: block;
					}
				}
			}

			h1{
				font-family: @headline;
				margin: 0;
				text-transform: uppercase;

				&:before, &:after{
					content: '';
					height: 4px;
					position: absolute;
					bottom: 50px;

					.market_haupt&{
						left: 50%;
						width: 40px;
						background-color: white;				
					}
					.market_extra&{
						// right: @margin * 2;
						top: @margin;
						right: 0;
						background-color: @color-brand;				
						width: 20px;
					}
				}

				&:before{

					.market_haupt&{
						transform: rotate(-45deg);
						margin: -2px 0 0 -35px;				
					}
					.market_extra&{
						transform: rotate(45deg);
						margin: 0 -6px -2px 0;
					}

					.closed&{
						.market_haupt&{
							transform: rotate(45deg);
						}
						.market_extra&{
							transform: rotate(-45deg);
						}
					}
				}
				&:after{

					.market_haupt&{
						transform: rotate(45deg);
						margin: -2px 0 0 -8px;				
					}
					.market_extra&{
						transform: rotate(-45deg);
						margin: 0 6px -2px 0;
					}

					.closed&{

						.market_haupt&{
							transform: rotate(-45deg);					
						}
						.market_extra&{
							transform: rotate(45deg);
						}
					}
				}



				.market_haupt&{
					color: white;
					text-align: center;

					font-size: 60px;
					line-height: 60px;

					@media @mobile-min{
						font-size: 70px;
						line-height: 70px;
					}

					@media @tablet-min{
						font-size: 150px;
						line-height: 150px;			
					}
				}

				.market_extra&{
					color: black;
					// font-size: 70px;
					// line-height: 70px;
					margin: 0 auto;
					padding-right: @margin * 3;
					max-width: 1024px;
					position: relative;

					font-size: 30px;
					line-height: 30px;

					@media @mobile-min{
						font-size: 40px;
						line-height: 40px;
					}

					// @media @tablet-min{
					// 	font-size: 150px;
					// 	line-height: 150px;			
					// }

					

					.closed&:after{
						display: block;
					}

					span{
						color: @color-brand;

						// float: left;
						display: block;
						// font-size: 20px;
						// line-height: 20px;

						@media @mobile-min{
							float: right;
							// font-size: 30px;
							// line-height: 30px;
							// margin-top: 8px;
						}
					}

					i{

					}
				}

				// &:after{
				// 	content: '/';
				// }

				// &:last-of-type:after{
				// 	content: '';
				// }
			}
		}
	}

	.hero{
		margin-bottom: @margin * 2;
		filter: none;
		position: relative;

		.market_hero_bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			filter: sepia(100%) brightness(65%);

			background-size: cover;
			background-position: center;
			background-repeat: no-repeat; 

		}


		.market_haupt&{
			
		}
		.closed.market_extra&{
			// display: none;
		}
	}

	.market_text{
		.closed&{
			// display: none;
		}
	}

	.market_gallery{

		height: 70vh;
		margin-top: @margin;

		.closed&{
			// display: none;
		}
	}

	.market_map{
		.market_extra&{
			margin-top: 0;
		}
		.closed&{
			// display: none;
		}
	}
}



.vendor_hero_availability{
	
}