html,
body{
	position: relative;
	// color: @color-copy;
	color: black;
	font-family: @standard;
	font-weight: @light;
	font-size: @base-font-size;
	line-height: @base-line-height;	
	overflow-x: hidden;
}

.main{
	width: 100%;
	position: relative;
	min-height: 100%;
}

.content{
	position: relative;
	float: left;
	overflow: hidden;
	width: 100%;

	&.invert{
		background-color: @color-brand-contrast;

		*{
			color: white !important;

		}

		a{
			color: @color-brand !important;
		}
	}
	// padding:4em 0;

	// @media @mobile-max {
	// 	padding: 2em 0;
	// }	

	&.c_plain{
		// h1{
		// 	color: white;
		// }
		padding: 10px 0;
		
		@media @mobile-min{
			padding: 40px 0;
		}
	}
}

.subsection{
	padding: 2em 0;

	@media @mobile-max {
		padding: 1em 0;
	}	
}

.summer {
	.covid,
	.index_intro,
	.second_text,
	.vendor_grid,
	.vendor_grid + .content,
	footer {
		display: none;
	}
}

.covid {
	img {
		display: block;
		width: 100%;
		margin-top: 60px;

		&.BAG_mobile {
			@media @mobile-min{
				display: none;
			}
		}
		&.BAG_desktop {
			display: none;
			@media @mobile-min{
				display: block;
			}

		}
	}
}

.vendor_content{
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	// opacity: 1;
	// visibility: visible;
	// transform: 0;
	// transition: all .5s ease;
	z-index: 4;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;

	&.closed{
		display: none;
		// opacity: 0;
		// visibility: hidden;
		// transform: translateY(@margin * 2);
	}

	.vendor_content_interior{
		// position: relative;
		// float: left;
		padding-top: @height_header;

		@media @mobile-max{
			padding-top: 65px;
		}
	}

}