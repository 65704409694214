.section_map{
	float: left;
	position: relative;
	height: 50vh;
	width: 100%;
	margin-top: @margin * 2;

	iframe{
		border: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
    filter: gray; /* IE6-9 */
	  -webkit-filter: grayscale(99%); /* Chrome 19+ & Safari 6+ */
	  -webkit-backface-visibility: hidden;  /* Fix for transition flickering */
	}
}